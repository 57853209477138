<template>
  <div class="">
    <v-btn small color="" class="mr-1 border-dark px-4 py-1 " height="4vh" @click="uploaddata = []; file = null; dialog = true">
      <v-icon class="pr-2">mdi-file-document-plus-outline</v-icon>
      <span class="text-capitalize">Bulk Upload</span>
    </v-btn>
    <lb-drawer :Maxwidth="(uploaddata.length > 0) ? '1665' : '700'" v-model="dialog" 
      heading="Bulk Upload" :loading="loading">
      <template v-slot:body>
        <div>
          <div class="d-flex align-center justify-space-between ">
            <span class="text-center mb-1" v-if="uploaddata.length === 0">Upload your document</span>
            <span v-else>Preview</span>

            <div class="d-flex">
              <v-btn text v-if="uploaddata.length>0" @click="uploaddata=[],showdatawitherror=false,dataerror=[],flag=false"> <v-icon>mdi-reload</v-icon> Re-Upload </v-btn>
              <v-btn  small class="border-dark d-flex align-center  "
                @click="downloadTemplate()">Download Template</v-btn>
            </div>
          </div>
          <div class="d-flex align-center justify-space-between ">
            <v-checkbox label="Show only items with error" color="caccounting" v-model="showdatawitherror"> </v-checkbox>
            <v-spacer></v-spacer>
          </div>
        </div>
          <div class="d-flex align-center justify-center">
            <DragDropfile v-if="uploaddata.length === 0" hidedetails :files="file" @update:files="updateFilesBulk"  />
        </div>   
        <div v-if="uploaddata.length > 0" class="radius-small border-on simple-table " style="max-height:73vh; overflow:scroll">
          <v-simple-table  >
            <template v-slot:default>
              <thead>
                <th class="text-center grayBackground" scope="sno">S No.</th>
                <th class="text-center grayBackground" scope="description">Description</th>
                <th class="text-center grayBackground" scope="category">Category</th>
                <th class="text-center grayBackground" scope="subcategory">Sub Category</th>
                <th class="text-center grayBackground" scope="location">Location</th>
                <th class="text-center grayBackground" scope="assertions">Assertions</th>
                <th class="text-center grayBackground" scope="purpose">Purpose</th>
                <th class="text-center grayBackground" scope="instructionHe">Instruction</th>
                <th class="text-center grayBackground" scope="preparer">Preparer</th>
                <th class="text-center grayBackground" scope="approver">Approver</th>
                <th v-if="!project" class="text-center grayBackground" scope="tags">Tags</th>
                <th class="text-center grayBackground" scope="duedays">Due days</th>
                <th class="text-center grayBackground" scope="glcode">Account No.</th>
              </thead>
              <v-slide-y-transition group tag="tbody" style="overflow:hidden !important;">
                <tr
                v-for="(row, index) in filteredData"
                :key="index"
                >
                <td>{{ index + 1 }}</td>
                <template v-for="(cell, key) in row">
                    <td v-if="errorsonly[index][key]" :key="key" class="text-caption pa-0  border-right-data" >
                      <div class="all-width all-height" style="border: 1px solid #F3B9BA">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-if="key === 'assertions' || key === 'location'"
                              class="d-inline-block"
                            >
                              <v-chip
                                v-for="(error, errorKey) in cell"
                                :key="errorKey"
                                label
                                v-bind="attrs"
                                v-on="on"
                                color="error"
                                small
                                outlined
                                class="pl-2 mb-1 mr-1"
                              >
                                <span class="pl-1">{{ error }}</span>
                              </v-chip>
                            </div>
                            <span v-else v-bind="attrs" v-on="on">
                              {{ errorsonly[index][key] }}
                            </span>
                          </template>
                        </v-tooltip>
                      </div>
                    </td>
                    <td v-else :key="key">
                      <div v-if=" key === 'tags' || key==='location'" class="d-flex align-center ">
                        <v-chip label  small outlined class="lightpurple caccounting--text pl-2 mb-1 mr-1">
                          <span class="pl-1">{{ cell[0] }}</span>
                        </v-chip>
                        <span v-if="cell.length>1" class="border-on px-1 ml-1">+{{cell.length-1}}</span>
                      </div>
                      <div v-else-if=" key==='approver' ||  key==='preparer'">
                        <v-avatar  v-for="(va, ka) in cell" :key="ka" size="24" outlined class="lightpurple caccounting--text  mb-1 mr-1">
                          {{ va[0] }}
                        </v-avatar>
                      </div>
                      <div v-else-if=" key==='assertions' " class="d-flex align-center ">
                        <span  v-if="cell?.length >=1">
                          {{ cell[0] }}
                        </span>
                        <span v-if="cell.length>1" class="border-on px-1 ml-1">+{{cell.length-1}}</span>
                      </div>
                      <div v-else-if=" key === 'glcode'">
                        <div v-for="(va, ka) in cell" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                            <span class="pl-1">{{ va}}</span>
                        </div>
                      </div>
                      <div v-else class="two-line-clamp">{{ cell }}</div>
                    </td>
                  </template>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
          
        </div>
        <p v-if="flag" class="error--text ma-0 pa-0 mt-4 text-caption">*These are fields where errors exist/ information missing. Please rectify and re-upload to continue. (Preparer & Approver cannot be the same user)</p>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <div v-if="uploaddata.length > 0">
       <v-btn small class="ml-2 white--text" color="black" v-if="!flag" @click="uploadData()">Upload</v-btn>
          <v-btn class=""  @click="cleardata()">Cancel
          </v-btn>
        </div>
      </template>
    </lb-drawer>
  </div>
</template>

<script>
import DragDropfile from '../../../../views/Inputs/DragDropfile.vue';
export default {
  name: 'financialclose_component_checklist_bulkupload',
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_checklist",
      // generateddescription: "",
      file: null,
      fileprocessing: {},
      flag: false,
      uploaddata: [],
      dataerror: [],
      dialog: false,
      loading: false,
      showdatawitherror:false,
      errorsonly:[]
    }
  },
  created() {
    this.dataerror=[]
  },
  activated() {
  },
  props: {
    project: {
      type: String,
    },
  },
  components:{
    DragDropfile
  },
  methods: {
   
    init() {
    },
    downloadTemplate(){
      if(this.project) this.$nova.downloadFile('', {}, '/v2/financialclose/analysis/getbulkchecklisttemplate');
      else this.$nova.downloadFile('', {}, '/v2/financialclose/masters/checklist/getbulksubmittemplate');
    },
    cleardata(){
      this.file =  null;
      this.fileprocessing =  {};
      this.flag =  false;
      this.uploaddata =  [];
      this.dataerror =  [];
      this.dialog =  false;
      this.loading =  false;
      this.showdatawitherror = false;
    },
    readFile(key) {
      let file = this.file;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['Description', 'Category', 'Sub Category','Location', 'Assertions', 'Purpose', 'Instruction', 'Preparer', 'Approver', 'Tags', 'Due Days', 'Account No'];
        // let possibleassertions = ['Existence', 'Occurrence', 'Rights and obligations', 'Completeness', 'Accuracy valuation and allocation', 'Cut–off', 'Classification', 'Presentation'];
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(async (dt) => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            let chartofaccountData = [];
            this.flag = false;
            await this.axios.post("/v2/financialclose/masters/chartofaccount/get")
              .then((dt) => {
                if (dt.data.status === "success") {
                  chartofaccountData.push({ "data": dt.data.data })
                }
              });
            for (let i = 0; i < dt.length; i++) {
              if (i > 0 && dt[i].length>0) {
                const el = dt[i];
                let rowdata = { "description": el[0], "category": el[1], "subcategory": el[2]} 
                rowdata.location = (el[3] || "base").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.assertions = (el[4] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.purpose = el[5]
                rowdata.instruction = el[6] 
                rowdata.preparer = (el[7] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.approver = (el[8] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.tags = (el[9] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.duedays = Number(el[10] || "") || null;
                rowdata.glcode = (el[11] || "").toString().split(",").filter(x => x.replaceAll(/\s/g, ''));
                let errors = {};
                if ((rowdata.description || "").toString() === "") errors["description"] = " ";
                if ((rowdata.category || "").toString() === "") errors["category"] = " ";
                if ((rowdata.subcategory || "").toString() === "") errors["subcategory"] = " ";
                if ((rowdata.approver || "").toString() === "") errors["approver"] = " ";
                if ((rowdata.preparer || "").toString() === "") errors["preparer"] = " ";
                if(this.project) delete rowdata.tags;
                else if ((rowdata.tags || []).length === 0) errors["tags"] = " ";
                // let notfoundassertions = [];
                // for (const i of rowdata.assertions) {
                //   if(possibleassertions.indexOf(i) === -1) notfoundassertions.push(i);
                // }
                // if(notfoundassertions.length > 0) errors["assertions"] = notfoundassertions.join(", ") + " assertions not found";
                if (rowdata.preparer.length > 0 && rowdata.approver.length > 0) {
                  let preparers = rowdata.preparer;
                  let approvers = rowdata.approver;
                  let duplicates = [];
                  for (const i of preparers) {
                    if (approvers.indexOf(i.trim()) > -1) duplicates.push(i);
                  }
                  if (duplicates.length > 0) errors["preparer"] = "'" + duplicates.join(",") + "' cannot be both preparer and approver";
                }
                // if(rowdata.location.length===0) errors["location"]="Location is required"
                for(const i of rowdata.glcode){
                  if (rowdata.glcode[i] != null && rowdata.glcode[i] !== "") {
                    let isGLValid = chartofaccountData[0].data.filter(obj => obj['glcode'] == rowdata.glcode[i]) || "";
                    if (isGLValid === "" || isGLValid.length === 0) {
                      errors["glcode"] = rowdata.glcode[i] + " Invalid GL Code";
                    }
                  }
                }
                if (Object.keys(errors).length > 0) this.flag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataerror = errordata;
            this.errorsonly = this.dataerror
            this.dialog = true;
          } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    uploadData() {
      this.loading = true;
      let url = "/v2/financialclose/masters/checklist/bulkadd";
      if(this.project) url = "/v2/financialclose/analysis/bulkaddchecklist/"+this.project;
      this.axios.post(url, { data: this.uploaddata }).then(dt => {
        if (dt.data.status === "success") {
          this.dialog = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "Checklist uploaded successfully");
        } else throw dt.data.message || "Error uploading Tasklist";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateFilesBulk(newFilesBulk) {
      this.file = newFilesBulk[0];      
      this.readFile('file')
    },
  },
  watch: {
    showdatawitherror(newVal) {
    if (newVal) {
      this.errorsonly = this.dataerror.filter(item => Object.keys(item).length > 0);
    } else {
      this.errorsonly = this.dataerror;
    }
  }
  },
  computed:{
    filteredData() {
      if (this.showdatawitherror) {
      return this.uploaddata.filter((_, rowIndex) =>
          Object.values(this.dataerror[rowIndex]).some((error) => error)
        );
    }
    return this.uploaddata; // Return all rows if not filtering errors
      }
  }
}
</script>